import React, { Component } from "react";
import { Segment } from "semantic-ui-react";
import API from "../../adapters/API";
import ActionNote from "../inputs/ActionNote";
import InputAction from "../inputs/InputAction";
import ResponseHistory from "../ResponseHistory";
import SubmitButtons from "./SubmitButtons";
import { deepEqual } from "../../utils/deepEqual";

class InputPage extends Component {
  state = {
    waitingForResponse: false,
  };

  componentDidUpdate(prevProps) {
    if (
      !deepEqual(prevProps.statements, this.props.statements) ||
      !deepEqual(prevProps.errors, this.props.errors)
    ) {
      this.setState({ waitingForResponse: false });
    }
  }

  componentDidMount() {
    this.props.statements
      .filter((s) => !!s.attributes && s.attributes.name)
      .forEach((s) => {
        this.setState({
          [s.attributes.name]:
            s.attributes.field_type === "Checkbox"
              ? s.attributes.value === "" || s.attributes.value === "false"
                ? "false"
                : "true"
              : s.attributes.value,
        });
      });
  }

  handleValueChange = (name) => (value) => this.setState({ [name]: value });

  submitButtonShouldBeDisabled = () => {
    const mandatoryStatements = this.props.statements.filter(
      (s) => s.attributes.mandatory
    );

    if (mandatoryStatements.length === 0) return false;

    const mandatoryStatementsWithNoValue = mandatoryStatements.filter((s) =>
      s.attributes.field_type === "Checkbox"
        ? this.state[s.attributes.name] !== "true"
        : !this.state[s.attributes.name]
    );

    return mandatoryStatementsWithNoValue.length > 0;
  };

  render = () => {
    const { statements, submit, apiRequest } = this.props;

    let errors = this.props.errors || {};
    if (typeof errors === "string") {
      try {
        errors = JSON.parse(errors);
      } catch (error) {
        console.error(`Cannot read these errors: ${errors}`, error);
      }
    }

    const { waitingForResponse } = this.state;

    return (
      <Segment.Group>
        {statements.map((statement) => {
          switch (statement.type) {
            case "input_action":
              return (
                <InputAction
                  key={statement.id}
                  handleChange={this.handleValueChange(
                    statement.attributes.name
                  )}
                  {...statement.attributes}
                  value={this.state[statement.attributes.name] || ""}
                  error={errors[statement.attributes.name]}
                />
              );
            case "extra_question":
              return (
                <InputAction
                  key={statement.id}
                  handleChange={this.handleValueChange(
                    statement.attributes.name
                  )}
                  {...statement.attributes}
                  value={this.state[statement.attributes.name]}
                  error={errors[statement.attributes.name]}
                  maxLength={statement.attributes.maxLength}
                  rows={statement.attributes.rows}
                />
              );
            case "action_note":
              return (
                <ActionNote
                  key={statement.id}
                  handleChange={this.handleValueChange(
                    statement.attributes.name
                  )}
                  {...statement.attributes}
                  value={this.state[statement.attributes.name]}
                />
              );
            case "response_history":
              return (
                <ResponseHistory
                  key={statement.id}
                  content={statement.attributes.sub_title}
                />
              );
            case "submit_buttons":
              return (
                <SubmitButtons
                  key={"buttons"}
                  errors={errors}
                  loading={waitingForResponse}
                  handleClick={(url, type, filename) => {
                    this.setState({ waitingForResponse: true });

                    if (type === "post") {
                      const state = { ...this.state };
                      delete state.waitingForResponse;
                      submit(state, url).then(() =>
                        this.setState({ waitingForResponse: false })
                      );
                    } else if (type === "download")
                      return API.downloadUrl(url, filename || "Report").then(
                        () => this.setState({ waitingForResponse: false })
                      );
                    else {
                      apiRequest(url);
                    }
                  }}
                  {...statement.attributes}
                  submitButtonShouldBeDisabled={this.submitButtonShouldBeDisabled()}
                />
              );
            default:
              return <></>;
          }
        })}
      </Segment.Group>
    );
  };
}

export default InputPage;

const baseUrl =
  process.env.REACT_APP_API_URL || "https://safertools.clearlysimpler.co.uk";
const apiUrl = `${baseUrl}`;
const memberUrl = `${baseUrl}/api/member`;
const help = `/api/help`;
const about = `/api/about`;
const privacy = `/api/privacy`;

const loginUrl = `${memberUrl}/login`;
const signupUrl = `${memberUrl}/signup`;
const restoreUrl = `${memberUrl}/restore`;
const resetUrl = `${memberUrl}/reset`;

let token = window.localStorage.getItem("token");

const staticPageUrls = {
  help,
  about,
  privacy
};

const isStaticUrl = url =>
  Object.values(staticPageUrls).includes(url) ||
  (url.includes("/api/") && !url.includes("/v1/"));

const getToken = () => token;

const setToken = newToken => {
  if (!newToken) {
    token = undefined;
    return window.localStorage.removeItem("token");
  }
  token = newToken.replace("Bearer ", "");
  window.localStorage.setItem("token", token);
};

const handleResponse = res => {
  if (res.ok) {
    if (res.headers.get("authorization")) {
      setToken(res.headers.get("authorization"));
    }
    return res.text().then(text => {
      try {
        return JSON.parse(text);
      } catch (error) {
        return { staticPageContent: text };
      }
    });
  } else if (res.status === 503) {
    return { code: 503 };
  } else if (res.status === 500) {
    return { code: 500, error: "Something went wrong!" + res.text() };
  } else {
    return res.text().then(text => {
      try {
        return JSON.parse(text);
      } catch (error) {
        return res;
      }
    });
  }
};

const options = (method = "get") => ({
  method,
  headers: {
    Authorization: `Bearer ${token}`
  }
});

const getUrl = (url = "/api/v1") =>
  fetch(`${apiUrl}${url}`, options()).then(handleResponse);

const postUrl = (url = "/api/v1") =>
  fetch(`${apiUrl}${url}`, options("post")).then(handleResponse);

const downloadUrl = (url, filename) =>
  fetch(`${apiUrl}${url}`, options("post"))
    .then(response => response.blob())
    .then(blob => {
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement("a");
      a.href = url;
      a.download = `${filename}`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    });

const login = user =>
  fetch(loginUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(user)
  }).then(handleResponse);

const forgotPassword = user =>
  fetch(restoreUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(user)
  }).then(handleResponse);

const resetPassword = (user, code) =>
  fetch(`${resetUrl}/${code}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(user)
  }).then(handleResponse);

const signup = user =>
  fetch(signupUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(user)
  }).then(handleResponse);

const postFormTo = (url, form) => {
  const o = options("post");
  o.headers = {
    ...o.headers,
    "Content-Type": "application/json"
  };
  return fetch(`${apiUrl}${url}`, {
    ...o,
    body: JSON.stringify(form)
  }).then(handleResponse);
};

const checkSignUpToken = (token, email, type) => {
  return fetch(`${memberUrl}/${type}/${token}/${email || ""}`)
    .catch(error => Promise.reject(error))
    .then(handleResponse)
    .catch(error => Promise.reject(error));
};

export default {
  getUrl,
  postUrl,
  login,
  setToken,
  getToken,
  postFormTo,
  checkSignUpToken,
  signup,
  forgotPassword,
  resetPassword,
  staticPageUrls,
  isStaticUrl,
  downloadUrl
};
